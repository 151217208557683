export const ApiUrl = {
  login: 'cashier/v1/login',
  allTransactions: 'cashier/v1/getTransactions',
  loadRoles: 'cashier/v1/getAllRolesOfCustomer',
  postTransaction: 'cashier/v1/order1',
  pointsOfPurchase: 'cashier/v1/getPointsOfPurchase',
  addTransaction: 'cashier/v1/addOrder',

  addRedeemCode: '/cashier/v1/sendRedeemCode',
  getProfileData: '/cashier/v1/profile'
} 